import React, { Component } from "react";
class SixthSection extends Component {
  render() {
    return (
      <section className="big-section">
        <div className="container">
          <div className="row client-logo-style-06 justify-content-center">
            {/* start client logo item */}
            <div
              className="col-10 col-md-3 col-sm-2 border-right border-bottom border-color-medium-gray text-center xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.1s"
            >
              <div className="client-box padding-4-rem-tb lg-padding-3-rem-tb xs-padding-4-rem-tb">
                <a href="#">
                  <img src="new_images/partner_3-no-bg.png" className="max-width-50" alt="" />
                </a>
              </div>
            </div>
            {/* end client logo item */}            
            {/* start client logo item */}
            <div
              className="col-10 col-md-3 col-sm-2 border-right border-bottom border-color-medium-gray text-center xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.1s"
            >
              <div className="client-box padding-4-rem-tb lg-padding-3-rem-tb xs-padding-4-rem-tb">
                <a href="#">
                  <img src="new_images/ncc_bw-nobg.png" className="max-width-50" alt="" />
                </a>
              </div>
            </div>
            {/* end client logo item */}
            {/* start client logo item */}
            <div
              className="col-10 col-md-3 col-sm-2 border-right border-bottom border-color-medium-gray text-center xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.1s"
            >
              <div className="client-box padding-4-rem-tb lg-padding-3-rem-tb xs-padding-4-rem-tb">
                <a href="#">
                  <img src="new_images/stGregory_bw.png" className="max-width-50" alt="" />
                </a>
              </div>
            </div>
            {/* end client logo item */}
            {/* start client logo item */}
            <div
              className="col-10 col-md-3 col-sm-2 border-right border-bottom border-color-medium-gray text-center sm-no-border-right wow animate__fadeIn"
              data-wow-delay="0.2s"
            >
              <div className="client-box padding-4-rem-tb lg-padding-3-rem-tb xs-padding-4-rem-tb">
                <a href="#">
                  <img src="new_images/oya-bwd.png" className="max-width-50" alt="" />
                </a>
              </div>
            </div>
            {/* end client logo item */}
            {/* start client logo item */}
            <div
              className="col-10 col-md-3 col-sm-2 border-right border-bottom border-color-medium-gray text-center xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.3s"
            >
              <div className="client-box padding-4-rem-tb lg-padding-3-rem-tb xs-padding-4-rem-tb">
                <a href="#">
                  <img src="new_images/evoke_partner-no-bg.png" className="max-width-50" alt="" />
                </a>
              </div>
            </div>
            {/* end client logo item */}
            {/* start client logo item */}
            <div
              className="col-10 col-md-3 col-sm-2 border-right border-color-medium-gray text-center sm-border-bottom xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.8s"
            >
              <div className="client-box padding-4-rem-tb lg-padding-3-rem-tb xs-padding-4-rem-tb">
                <a href="#">
                  <img src="new_images/hot_stop-bw-no-bg.png" className="max-width-50" alt="" />
                </a>
              </div>
            </div>
            {/* end client logo item */}
            {/* start client logo item */}
            <div
              className="col-10 col-md-3 col-sm-2 border-right border-color-medium-gray text-center sm-border-bottom xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.8s"
            >
              <div className="client-box padding-4-rem-tb lg-padding-3-rem-tb xs-padding-4-rem-tb">
                <a href="#">
                  <img src="new_images/last_price-no-bg.png" className="max-width-50" alt="" />
                </a>
              </div>
            </div>
            {/* end client logo item */}          
            {/* start client logo item */}
            <div
              className="col-10 col-md-3 col-sm-2 border-right border-color-medium-gray text-center sm-border-bottom xs-no-border-right wow animate__fadeIn"
              data-wow-delay="0.8s"
            >
              <div className="client-box padding-4-rem-tb lg-padding-3-rem-tb xs-padding-4-rem-tb">
                <a href="#">
                  <img src="new_images/ngd-bwd.png" className="max-width-50" alt="" />
                </a>
              </div>
            </div>
            {/* end client logo item */}                      
            </div>
        </div>
      </section>
    );
  }
}

export default SixthSection;
